import * as React from "react"

const MarketingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{`path:hover{fill:${props.hovercolor}}`}</style>
    <path d="M744.6 1238.5 477.2 780.1l444.7-485.7 442.9 776.7-620.2 167.4zM559.5 788.9l217 372 487.7-131.7-354.9-622.4-349.8 382.1zM1538.4 509.1l-186.8 111-17.1-28.6-17-28.6 186.9-111.1 17 28.6zM1251.4 227.5l-51.8 219.8-64.9-15.3 51.9-219.8 32.4 7.6zM1611.4 814.9l-9.4 31.9-9.4 32-212.5-62.7 9.5-32 9.4-32z" />
    <path d="M493.8 1325.6c-7.1-12.5-3-28.5 9.3-35.8l50.9-30.3c43.8-26.1 98.6-15.7 130.7 22 34.5-16 69-34.1 102.8-54.2l28.1-16.7-274.7-483.5-29.2 17.4c-62.9 37.4-120.6 79.6-170.5 124.5-50 44.9-92.2 92.3-124.4 140.3-67 100-82.8 192.2-44.5 259.6 22.2 39.1 61.6 67.1 114 80.9 26.4 7 56 10.5 88.2 10.5 39.8 0 83.6-5.3 130-15.9l-10.7-18.8z" />
    <path d="M715.7 1584c-16.6 0-33.2-2.2-49.7-6.5-51.2-13.6-94-46.7-120.5-93.4l-80.7-142c-16-28.2-6.5-64.4 21.2-80.9l50.9-30.3c31.6-18.8 68.5-24 104-14.6 35.9 9.5 65.8 32.7 84.4 65.3l110.3 194.1c16 28.2 6.5 64.4-21.2 80.9-30.5 18.1-64.3 27.4-98.7 27.4zm-189.6-269.1 77.4 136.2c17.6 31 45.9 52.9 79.6 61.9 30.9 8.2 62.9 4.5 91.1-10.3l-106.9-188.1c-9.6-16.9-25.1-29-43.5-33.8-18-4.8-36.7-2.1-52.8 7.5l-44.9 26.6z" />
  </svg>
)

export { MarketingIcon }