import React from "react";
import AppButton from "./AppButton";
import data from "./DocumentIcons";
export default function ContractCard(props) {
  return (
    <div className="col-lg-3" style={{ marginBottom: 30 }}>
      <div
        style={{
          width: "95%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-4"
              style={{ paddingRight: 0, textAlign: "center" }}
            >
              <img
                style={{ width: "80%", marginTop: 5 }}
                src={data[props.item.type]}
              />
            </div>
            <div className="col-lg-8">
              <div>{props.item.name}</div>
              <div>
                {props.item.action_required ? (
                  <p style={{ margin: 0, color: "red", fontSize: 14 }}>
                    Action Required
                  </p>
                ) : (
                  <p
                    style={{
                      margin: 0,
                      color: "#0564e9",
                      fontSize: 14,
                    }}
                  >
                    No Action Required
                  </p>
                )}
              </div>
              <div style={{ fontSize: 14 }}>
                <b>{props.item.children}</b> Active Variations
              </div>
            </div>
          </div>
        </div>

        <AppButton
          text="view"
          btnIcon="visibility"
          style={{
            color: "white",
            background: "#5192ec",
            width: "100%",
            marginTop: 10,
          }}
          onClick={() => props.setActive(props.item.id)}
        />
      </div>
    </div>
  );
}
