// Staging Config -- Comment out the correct config depending on build location
const appURLtype =
  process.env.NODE_ENV === "production"
    ? "https://l3-optics.home.mybe.software"
    : "http://localhost:8080";

const staging = false;

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: appURLtype,
  tenantName: "l3-optics",
  staging: staging,
  supportAppUUID: "00f41bb9-6044-4ed1-8acf-c495aa24f055",
  portalAppId: "de3f0396-a603-47fd-8563-27d7e64135c0"
};

// Prod Config
// const appURLtype =
//   process.env.NODE_ENV === "production"
//     ? "https://unity.portal.mybe.software"
//     : "http://localhost:8080";

// const staging = false;

// module.exports = {
//   // ports
//   serverPort: 8080,
//   //This app url
//   appURL: appURLtype,
//   tenantName: "unity",
//   staging: staging,
//   supportAppUUID: "7bc02a60-47b5-4ca7-afb2-a1e0bdbb610b"
// };
