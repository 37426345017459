import * as React from "react"

const HQIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{`path:hover{fill:${props.hovercolor}}`}</style>
    <path d="M1039.2 660.5c-20.7 0-37.5 7.2-50.4 21.6s-19.3 36.1-19.3 65c0 29.4 6.4 51.4 19.3 66 12.9 14.6 29.1 21.9 48.8 21.9 7.3 0 14.2-1.2 20.8-3.6-10.3-6.7-20.8-12-31.4-15.8l14.2-29c16.7 5.7 32.7 14.3 47.9 25.7 6.5-7.7 11.4-16.9 14.7-27.8 3.3-10.9 4.9-23.3 4.9-37.4 0-29.1-6.4-50.8-19.2-65.1-12.8-14.3-29.6-21.5-50.3-21.5z" />
    <path d="M1604.6 474.1H175.4c-32.9 0-59.6 26.7-59.6 59.6v712.6c0 32.9 26.7 59.6 59.6 59.6h634.7v-203.7c0-32.2 26.1-58.3 58.3-58.3h55.7c32.2 0 58.3 26.1 58.3 58.3v203.7h622.2c32.9 0 59.6-26.7 59.6-59.6V533.7c-.1-32.9-26.7-59.6-59.6-59.6zM411.9 1178.9H261.4c-17.4 0-31.5-14.1-31.5-31.5V996.9h182v182zm0-395.8h-182V632.6c0-17.4 14.1-31.5 31.5-31.5h150.5v182zM875 873h-50.8V763h-99.5v110h-50.8V621.4h50.8v99h99.5v-99H875V873zm276.9 25.2c-7.7-2.3-15.2-5.4-22.5-9.4-1.6-.8-12.9-8.2-33.8-22.3-16.5 7.2-34.7 10.8-54.7 10.8-38.7 0-69-11.4-90.9-34.1-21.9-22.8-32.9-54.7-32.9-95.9 0-41.1 11-73 32.9-95.8 22-22.8 51.8-34.2 89.4-34.2 37.3 0 66.9 11.4 88.7 34.2 21.9 22.8 32.8 54.8 32.8 95.8 0 21.7-3 40.8-9.1 57.3-4.6 12.6-11.9 24.8-22 36.6 12.5 8.9 26 16 40.7 21.3l-18.6 35.7zm398.2 249.2c0 17.4-14.1 31.5-31.5 31.5h-150.5v-182h182v150.5zm0-364.3h-182v-182h150.5c17.4 0 31.5 14.1 31.5 31.5v150.5z" />
  </svg>
)

export { HQIcon }