import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Grid from '@material-ui/core/Grid';
import { Link, NavLink } from "react-router-dom";
import AuthContext from "../services/AuthContext";
import { AppsIcon } from "../assets/svgs/AppsIcon";
import { DocumentsIcon } from "../assets/svgs/DocumentsIcon";
import { ContractsIcon } from "../assets/svgs/ContractsIcon"
import { ContactsIcon } from "../assets/svgs/ContactsIcon";
import { SupportIcon } from "../assets/svgs/SupportIcon";
import { FeedbackIcon } from "../assets/svgs/FeedbackIcon";
import "./layout.css";

export default function Layout({ children }) {
  const context = useContext(AuthContext);
  const access = context.user.access;
  const [pathname, setPathname] = useState("");

  const uID = context.user.app_uuid;

  const getPathname = () => {
    setPathname( window.location.pathname );
  }

  useEffect(() => {
    getPathname();
  }, []);

  return pathname ? (
    <div
      className="container-fluid"
      style={{ paddingBottom: 20, paddingLeft: 0, paddingRight: 0 }}
    >
      <Header />
      <div className="container portal-header">
        <Grid container spacing={3} style={{ justifyContent: "center" }}>
          {access.apps["A"] && access.apps["R"] && (
            <Grid item xs={2}>
              <div className="img-with-text">
                <NavLink to="/apps" onClick={() => setPathname("/apps")} className="portal-header-item">
                  <AppsIcon primarycolor={ pathname === "/" || pathname === "/apps" ? "red": "darkblue" } width="100px" height="100px"/>
                  <p className="apps-title">Apps </p>
                </NavLink>
              </div>
            </Grid>
          )}
          {(access.clients["A"] && access.clients["R"]) ? (
            <Grid item xs={2}>
              <div className="img-with-text">
                <NavLink to="/customers" onClick={() => setPathname("/customers")} className="portal-header-item">
                  <ContactsIcon primarycolor={ pathname === "/customers" ? "red": "darkblue" } width="100px" height="100px"/>
                  <p className="apps-title">Customers</p>
                </NavLink>
              </div>
            </Grid>
          ) : (
            // & if it is just a customer logged in they can see thir own details
            <Grid item xs={2}>
              <div className="img-with-text">
                <NavLink to={`/clients/update/${context.user.client}`} onClick={() => setPathname(`/clients/update/${context.user.client}`)} className="portal-header-item">
                  <ContactsIcon primarycolor={ pathname === `/clients/update/${context.user.client}` ? "red": "darkblue" } width="100px" height="100px"/>
                  <p className="apps-title">My Details</p>
                </NavLink>
              </div>
            </Grid>
          )}

          <Grid item xs={2}>
            <div className="img-with-text">
              <NavLink to="/document-viewer" onClick={() => setPathname("/document-viewer")} className="portal-header-item">
                <DocumentsIcon primarycolor={ pathname === "/document-viewer" ? "red": "darkblue" } width="100px" height="100px"/>
                <p className="apps-title">Documents</p>
              </NavLink>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className="img-with-text">
              <NavLink to="/contracts-view" onClick={() => setPathname("/contracts-view")} className="portal-header-item">
                <ContractsIcon primarycolor={ pathname === "/contracts-view" ? "red": "darkblue" } width="100px" height="100px"/>
                <p className="apps-title">Contracts</p>
              </NavLink>
            </div>
          </Grid>
          
          {(access.clients["A"] && access.clients["R"]) ? ( <></>
            // <Grid item xs={2}>
            //   <div className="img-with-text">
            //     <a href="https://unity.support.mybe.software/" className="portal-header-item">
            //       <SupportIcon primarycolor="darkblue" width="100px" height="100px" className="portal-icon"/>  
            //       <p className="apps-title">Support</p>
            //     </a>
            //   </div>
            // </Grid>
          ) : (
            <Grid item xs={2}>
              <div className="img-with-text">
                <NavLink to="/tickets" onClick={() => setPathname("/tickets")} className="portal-header-item">
                  <SupportIcon primarycolor={ pathname === "/tickets" ? "red" : "darkblue" } width="100px" height="100px"/>
                  <p className="apps-title">Support Tickets</p>
                </NavLink>
              </div>
            </Grid>
          )}
          {access.clients["A"] && access.clients["R"] && (
            <Grid item xs={2}>
              <div className="img-with-text">
                <NavLink to="/feedback" onClick={() => setPathname("/feedback")} className="portal-header-item">
                  <FeedbackIcon primarycolor={ pathname === "/feedback" ? "red" : "darkblue" } width="100px" height="100px"/>
                  <p className="apps-title">Feedback</p>
                </NavLink>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      {children}
    </div>
  ) : (
    <p>Loading...</p>
  );
}
