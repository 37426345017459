import React, { useEffect, useState } from "react";
import { getContractsDocUrl, getContractsRelations } from "../api/contracts";
import AppButton from "./common/AppButton";
import ContractCardItemView from "./common/ContractCardItemView";

import ContractPreviewPane from "./common/ContractPreviewPane";

export default function ContractCardView(props) {
  const set = [
    {
      type: 1,
      id: 1,
      name: "My Contract 1",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 2",
      action_required: 1,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 3",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 4",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 5",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 6",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 7",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 8",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 9",
      action_required: 0,
      children: 12,
    },
  ];
  const [docs, setDocs] = useState(false);
  const [details, setDetails] = useState(false);
  const [downloading, setDownloading] = useState(false);

  console.log(props.active);

  const getDoc = async (id) => {
    setDownloading(id);
    const res = await getContractsDocUrl(id);
    setDownloading(false);
    var win = window.open(res.data, "_blank");
    win.focus();
  };

  const getData = async () => {
    const res = await getContractsRelations(props.active);
    console.log("the active doc",res);
    setDocs(res.data);
  };

  const handleSetDetails = (ref) => {
    console.log("doc",details);
    console.log("doc changed to ref",ref);
    const docObj = docs.find((e) => e.ref === ref);
    setDetails(docObj);
  };

  useEffect(() => {
    getData();
  }, []);

  if (docs) {
    return (
      <div className="container">
        <div className="row" style={{ marginTop: 20, marginBottom: 10 }}>
          <div className="col-lg-12">
            <AppButton
              text="back"
              btnIcon="arrow_back"
              style={{
                color: "white",
                background: "#0367f2",
              }}
              onClick={() => props.setActive(false)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 border-grey">
            <ContractPreviewPane
              details={details}
              docs={docs}
              getDoc={getDoc}
            />
          </div>

          <div
            className="col-lg-6"
            style={{ height: 800, overflowY: "scroll" }}
          >
            <div className="row">
              {docs.map((item, key) => {
                return (
                  <ContractCardItemView
                    item={item}
                    handleSetDetails={handleSetDetails}
                    getDoc={getDoc}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <p>Loading</p>;
  }
}
