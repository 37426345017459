import React, { useState } from "react";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import SignatureCanvas from "react-signature-canvas";
import AppButton from "./AppButton";
import data from "./DocumentIcons";
import ApiLoader from "./ApiLoader";
import { signContracts } from "../../api/contracts";
export default function ContractPreviewPane({ details, docs, getDoc }) {
  const [sign, setSign] = useState(false);
  const [loading, setLoading] = useState({ state: false, data: false });
  var sigPad = React.createRef();

  const handleSend = async () => {
    var obj = {
      signature: sigPad.getTrimmedCanvas().toDataURL("image/png"),
      ...details,
    };
    console.log(obj);

    await signContracts(obj);
  };

  if(details) {
    return (
      <>
        <ApiLoader status={loading.state} data={loading.data} />
        <div style={{ textAlign: "center" }}>
          {!details.variation && <div className="original-doc2">ORIGINAL</div>}
          <img style={{ width: "50%", marginTop: 5 }} src={data[1]} />
        </div>
        <div style={{ textAlign: "center", marginTop: 5, fontSize: 24 }}>
          {details.name}
        </div>
        <div className="container-fluid">
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-4" style={{ textAlign: "center" }}>
              <div style={{ display: "inline-block" }}>
                <EventAvailableIcon size={14} />
              </div>
              <div style={{ display: "inline-block", marginLeft: 10 }}>
                Date Generated
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                {details.generated_date}
              </div>
            </div>
            <div className="col-lg-4" style={{ textAlign: "center" }}>
              <div style={{ display: "inline-block" }}>
                <EventAvailableIcon size={14} />
              </div>
              <div style={{ display: "inline-block", marginLeft: 10 }}>
                Date Signed
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                {details.signed_date}
              </div>
            </div>
            <div className="col-lg-4" style={{ textAlign: "center" }}>
              <div style={{ display: "inline-block" }}>
                <EventAvailableIcon size={14} />
              </div>
              <div style={{ display: "inline-block", marginLeft: 10 }}>
                Date Cancelled
              </div>
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: 5,
                }}
              >
                {details.cancelled_date}
              </div>
            </div>
          </div>
        </div>
        {details.signed ? (
          <div style={{ marginTop: 20 }}>
          <AppButton
            text="view"
            btnIcon="visibility"
            style={{
              color: "white",
              background: "#39c14a",
              width: "100%",
              marginTop: 10,
              paddingTop: 15,
              paddingBottom: 15,
            }}
            onClick={() => getDoc(details.signed_pdf_id)}
          />
        </div>
        ) : !sign ? (
          <div style={{ marginTop: 20 }}>
            <AppButton
              text="sign"
              btnIcon="border_color"
              style={{
                color: "white",
                background: "#5192ec",
                width: "100%",
                marginTop: 10,
                paddingTop: 15,
                paddingBottom: 15,
              }}
              onClick={() => setSign(true)}
            />
            <AppButton
              text="view"
              btnIcon="visibility"
              style={{
                color: "white",
                background: "#39c14a",
                width: "100%",
                marginTop: 10,
                paddingTop: 15,
                paddingBottom: 15,
              }}
              onClick={() => getDoc(details.generated_pdf_id)}
            />
          </div>
        ) : (
          <>
            <p className="sign-title">Please Sign Below</p>
            <div
              style={{
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "solid",
              }}
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                ref={(ref) => {
                  sigPad = ref;
                }}
              />
  
              <AppButton
                text="submit"
                btnIcon="send"
                style={{
                  color: "white",
                  background: "#39c14a",
                  width: "100%",
                  marginTop: 10,
                  paddingTop: 15,
                  paddingBottom: 15,
                }}
                onClick={() => handleSend()}
              />
            </div>
          </>
        )}
      </>
    );
  } else {
    return <></>;
  }
}
