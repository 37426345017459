import * as React from "react"

const CustomersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{`path:hover{fill:${props.hovercolor}}`}</style>
    <path d="M250.4 637.5c-96.8 0-175.5 78.7-175.5 175.5v789.7h452.4V637.5H250.4zm142.4 602.7H222.4v-140.9c0-16.3 13.2-29.5 29.5-29.5h140.9v170.4zm0-279.2H222.4V820.1c0-16.3 13.2-29.5 29.5-29.5h140.9V961zM1529.6 637.5h-276.9v965.2h452.4V813c0-96.8-78.7-175.5-175.5-175.5zm34.5 602.7h-170.5v-170.5h140.9c16.3 0 29.5 13.2 29.5 29.5v141zm0-279.2h-170.5V790.5h140.9c16.3 0 29.5 13.2 29.5 29.5v141z" />
    <path d="M1259.5 1602.7H523.4c-2.7 0-4.9-2.2-4.9-4.9V321.6c0-103.4 83.8-187.2 187.2-187.2h371.6c103.4 0 187.2 83.8 187.2 187.2v1276.2c-.1 2.7-2.3 4.9-5 4.9zm-694.3-46.8h652.4V321.6c0-77.5-62.9-140.4-140.4-140.4H705.6c-77.5 0-140.4 62.9-140.4 140.4v1234.3z" />
    <path d="M848.6 559.7H678.2V418.8c0-16.3 13.2-29.5 29.5-29.5h140.9v170.4zM848.6 838.9H678.2V698c0-16.3 13.2-29.5 29.5-29.5h140.9v170.4zM939.2 559.7h170.5V418.8c0-16.3-13.2-29.5-29.5-29.5h-141v170.4zM939.2 838.9h170.5V698c0-16.3-13.2-29.5-29.5-29.5h-141v170.4zM846.7 1119H676.2V978.1c0-16.3 13.2-29.5 29.5-29.5h140.9V1119zM937.2 1119h170.5V978.1c0-16.3-13.2-29.5-29.5-29.5h-141V1119z" />
  </svg>
)

export { CustomersIcon }
