import React, { useContext, useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import AppButton from "./common/AppButton";
import { getDocumentsByCustomer, getLink } from "../api/documents";
import AuthContext from "../services/AuthContext";
import ContractCard from "../components/common/ContractCard";
import ContractCardView from "./ContractCardView";
import { getContractsIndex } from "../api/contracts";
export default function ContractsView(props) {
  const set = [
    {
      type: 1,
      id: 1,
      name: "My Contract 1",
      action_required: 1,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 2",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 3",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 4",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 5",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 6",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 7",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 8",
      action_required: 0,
      children: 12,
    },
    {
      type: 1,
      id: 1,
      name: "My Contract 9",
      action_required: 0,
      children: 12,
    },
  ];
  const [docs, setDocs] = useState(false);
  const [active, setActive] = useState(false);

  const getContracts = async () => {
    const res = await getContractsIndex();
    setDocs(res.data);
  };

  useEffect(() => {
    getContracts();
  }, []);

  console.log("contracts", docs);

  return (
    <div className="container">
      {docs ? (
        <>
          {active ? (
            <ContractCardView active={active} setActive={setActive} />
          ) : (
            <div className="row" style={{ marginTop: 20 }}>
              {docs.map((item) => {
                return <ContractCard item={item} setActive={setActive} />;
              })}
            </div>
          )}
        </>
      ) : (
        <p>No Contracts Yet</p>
      )}
    </div>
  );
}
