/**
 * @middleware      \ Portal Middleware
 * @description     \ Profile related routes
 * @baseURL         \ https://portal-middleware.<SERVER_DOMAIN>/api/agents
 */
import config from "../config";

const getProfile = async (id) => {
  const data = await fetch(`${config.appURL}/api/profile/getSingle?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const updateProfile = async (id, obj) => {
  const data = await fetch(`${config.appURL}/api/profile/update?id=${id}`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const saveImage = async (id, obj) => {
  const data = await fetch(`${config.appURL}/api/profile/saveImage?id=${id}`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const savePassword = async (id, obj) => {
  const data = await fetch(
    `${config.appURL}/api/profile/savePassword?id=${id}`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const res = await data.json();

  return res;
};

const getAgents = async () => {
  const data = await fetch(`${config.appURL}/api/profile/agents`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getManagersIndex = async () => {
  const data = await fetch(`${config.appURL}/api/profile/managerIndex`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const getGroupIndex = async () => {
  const data = await fetch(`${config.appURL}/api/profile/groupIndex`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
};

const createUser = async (obj) => {
  const data = await fetch(
    `${config.appURL}/api/user/create`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const payload = await data.json();
  payload.status = data.status;

  return payload;
}

const lockAccount = async (id) => {
  const data = await fetch(`${config.appURL}/api/user/lockAccount?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const unlockAccount = async (id) => {
  const data = await fetch(`${config.appURL}/api/user/unlockAccount?id=${id}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const getRegistrations = async (uuid) => {
  const data = await fetch(`${config.appURL}/api/user/getRegistrations?uuid=${uuid}`, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const res = await data.json();

  return res;
}

const alterRegistrations = async (obj) => {
  const data = await fetch(
    `${config.appURL}/api/user/registrations/atler`,
    {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const payload = await data.json();
  payload.status = data.status;

  return payload;
}

export {
  getProfile,
  updateProfile,
  saveImage,
  savePassword,
  getAgents,
  getManagersIndex,
  getGroupIndex,
  createUser,
  lockAccount,
  unlockAccount,
  getRegistrations,
  alterRegistrations
};
