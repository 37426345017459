import React from "react";
import AppButton from "./AppButton";
import data from "./DocumentIcons";
export default function ContractCardItemView({
  item,
  handleSetDetails,
  getDoc,
}) {
  return (
    <div
      className="col-lg-4"
      style={{ marginBottom: 20, textAlign: "center" }}
    >
      <div
        style={{
          width: "95%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div>
          {!item.variation && <div className="original-doc">ORIGINAL</div>}
          <img style={{ width: "40%", marginTop: 5 }} src={data[item.type]} />
        </div>
        <div>{item.name}</div>
        <div>
          {!item.signed ? (
            <p style={{ margin: 0, color: "red", fontSize: 14 }}>
              Action Required
            </p>
          ) : (
            <p
              style={{
                margin: 0,
                color: "#0564e9",
                fontSize: 14,
              }}
            >
              No Action Required
            </p>
          )}
        </div>
        {item.signed ? (
          <AppButton
            text="view"
            btnIcon="visibility"
            style={{
              color: "white",
              background: "#39c14a",
              width: "100%",
              marginTop: 10,
            }}
            onClick={() => getDoc(item.signed_pdf_id)}
          />
        ) : (
          <AppButton
          text="view"
          btnIcon="visibility"
          style={{
            color: "white",
            background: "#39c14a",
            width: "100%",
            marginTop: 10,
          }}
          onClick={() => getDoc(item.generated_pdf_id)}
        />
        )}
        {item.action_required ? (
          <AppButton
            text="view actions"
            btnIcon="warning"
            style={{
              color: "white",
              background: "red",
              width: "100%",
              marginTop: 10,
            }}
            onClick={() => handleSetDetails(item.ref)}
          />
        ) : (
          <AppButton
            text="details"
            btnIcon="search"
            style={{
              color: "white",
              background: "#5192ec",
              width: "100%",
              marginTop: 10,
            }}
            onClick={() => handleSetDetails(item.ref)}
          />
        )}
      </div>
    </div>
  );
}
