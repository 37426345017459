import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../App.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "../services/AuthContext";
import Layout from "../pages/Layout";
import Home from "../pages/Home";
import Clients from "../pages/clients";
import ClientView from "../pages/clients/view";
import ClientCreate from "../pages/clients/create";
import ClientEdit from "../pages/clients/edit";
import Apps from "../components/Apps";
import Settings from "../components/Settings";
import DocumentView from "../pages/documents/DocumentView";
import Tickets from "../pages/tickets/index";
import ContractsView from "../components/Contracts";
import DetailsView from "../pages/clients/view";
import TicketsCreate from "../pages/tickets/create";
import TicketsEdit from "../pages/tickets/edit";
import Feedback from "../pages/feedback";
import Profile from "../pages/profile/edit";
import ProfileIndex from "../pages/profile/index";
import ProfileView from "../pages/profile/view";
import ProfileCreate from "../pages/profile/create";
import ProfileApps from "../pages/profile/apps";
import PositionCreate from "../pages/positions/create";
import PositionIndex from "../pages/positions/index";
import PositionEdit from "../pages/positions/edit";

function Interface() {
  const context = useContext(AuthContext);
  const access = context.user.access;

  return (
    <Router>
      <Layout>
        {!context.user.client ? (
          <Route
            render={({ location }) => (
              <TransitionGroup className="transition-group">
                <CSSTransition
                  key={location.key}
                  timeout={300}
                  classNames="fade"
                >
                  <section className="route-section">
                    <Switch location={location}>
                      <Route path="/location" exact component={Location} />
                      <Route path="/" exact component={Apps} />
                      <Route path="/apps" exact component={Apps} />
                      <Route path="/settings" exact component={Settings} />
                      {access.clients["A"] && access.clients["R"] && (
                        <Route path="/customers" exact component={Clients} />
                      )}
                      {access.clients["A"] && access.clients["R"] && (
                        <Route
                          path="/clients/view/:id"
                          exact
                          component={ClientView}
                        />
                      )}
                      {access.clients["A"] && access.clients["C"] && (
                        <Route
                          path="/clients/create"
                          exact
                          component={ClientCreate}
                        />
                      )}
                      {/* Only getting away with NOT checking for Update access here because customers can edit themselves and a check for id is dome when posting! */}
                      {access.clients["A"] && (
                        <Route
                          path="/clients/update/:id"
                          exact
                          component={ClientEdit}
                        />
                      )}
                      <Route
                        path="/document-viewer"
                        exact
                        component={DocumentView}
                      />
                      <Route
                        path="/contracts-view"
                        exact
                        component={ContractsView}
                      />
                      <Route path="/tickets" exact component={Tickets} />
                      <Route
                        path="/tickets/create"
                        exact
                        component={TicketsCreate}
                      />
                      <Route
                        path="/tickets/edit/:id"
                        exact
                        component={TicketsEdit}
                      />
                      <Route path="/feedback" exact component={Feedback} />

                      <Route path="/profile" exact component={Profile} />

                      <Route path="/profiles" exact component={ProfileIndex} />

                      <Route path="/profile/edit/applications" exact component={ProfileApps} />

                      <Route path="/user/create" exact component={ProfileCreate} />

                      <Route path="/positions" exact component={PositionIndex} />

                      <Route path="/position/create" exact component={PositionCreate} />

                      <Route path="/position/edit/:id" exact component={PositionEdit} />

                      <Route
                        path="/profile/view/:id"
                        exact
                        component={ProfileView}
                      />

                      <Route
                        path="/profile/edit/:id"
                        exact
                        component={Profile}
                      />
                    </Switch>
                  </section>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        ) : (
          <Route
            render={({ location }) => (
              <TransitionGroup className="transition-group">
                <CSSTransition
                  key={location.key}
                  timeout={300}
                  classNames="fade"
                >
                  <section className="route-section">
                    <Switch location={location}>
                      <Route path="/location" exact component={Location} />
                      <Route path="/" exact component={ClientEdit} />
                      {access.clients["A"] && access.clients["R"] && (
                        <Route
                          path="/clients/view/:id"
                          exact
                          component={ClientView}
                        />
                      )}
                      {/* Only getting away with NOT checking for Update access here because customers can edit themselves and a check for id is dome when posting! */}
                      {access.clients["A"] && (
                        <Route
                          path="/clients/update/:id"
                          exact
                          component={ClientEdit}
                        />
                      )}
                      <Route
                        path="/document-viewer"
                        exact
                        component={DocumentView}
                      />
                      <Route
                        path="/contracts-view"
                        exact
                        component={ContractsView}
                      />
                      <Route path="/tickets" exact component={Tickets} />
                      <Route
                        path="/tickets/create"
                        exact
                        component={TicketsCreate}
                      />
                      <Route
                        path="/tickets/edit/:id"
                        exact
                        component={TicketsEdit}
                      />
                    </Switch>
                  </section>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        )}
      </Layout>
    </Router>
  );
}

export default Interface;
