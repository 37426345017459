import * as React from "react"

const PurchasingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1780 1780"
    style={{
      enableBackground: "new 0 0 1780 1780",
      fill: `${props.primarycolor ? props.primarycolor : "black"}`,
      width: `${props.width ? props.width : "70px"}`,
      height: `${props.height ? props.height : "70px"}`
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{`path:hover{fill:${props.hovercolor}}`}</style>
    <path d="m1335.6 983.2-38.6-15.6 89.3-221.9h248.3v41.7h-220.2zM1283 1436.3c-38.5 0-69.6 31.2-69.6 69.6 0 38.5 31.2 69.6 69.6 69.6 38.5 0 69.6-31.2 69.6-69.6 0-38.4-31.1-69.6-69.6-69.6zm-.9 104.1c-18.2 0-33-14.8-33-33s14.8-33 33-33 33 14.8 33 33-14.8 33-33 33zM568.8 1434.9c-38.5 0-69.6 31.2-69.6 69.6 0 38.5 31.2 69.6 69.6 69.6 38.5 0 69.6-31.2 69.6-69.6 0-38.4-31.2-69.6-69.6-69.6zm-.9 104.1c-18.2 0-33-14.8-33-33s14.8-33 33-33 33 14.8 33 33-14.8 33-33 33z" />
    <path d="M1317.7 885.2H974.2v72.7h124.4l-248.9 248.9-248.8-249h124.4v-72.7h-440c-42.9 0-70.2 44.9-49.5 81.6L463.5 1372c9.9 17.6 28.9 28.6 49.5 28.6h804.7c31.2 0 56.5-24.7 56.5-55.1V940.3c0-30.5-25.3-55.1-56.5-55.1z" />
    <path d="m849.8 1221.4-274-274H715v-515h269.7v515.1h139.2l-274.1 273.9zM626 968.3 849.8 1192l223.7-223.7H963.8V453.2H735.7v515.1H626z" />
  </svg>
)

export { PurchasingIcon }